import React, { useEffect, useMemo, useState } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "./routes";

// Soft UI Dashboard PRO React contexts
import { setMiniSidenav, useSoftUIController } from "./context";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import SignIn from "./pages/sign-in";
import OrganizationUsers from "./pages/users";
import MerchantReporting from "./pages/merchant-reporting";
import ApiKeys from "./pages/ApiKeyManagement";
import TransactionDetail from "./pages/transaction-detail";
import ForgotPassword from "./pages/sign-in/ForgotPassword";
import ChangePassword from "./pages/sign-in/СhangePassword";
import { hasPermissions } from "./utils/roles";
import { getToken } from "./utils/cache";
import SchedulerDetails from "./pages/reports/pages/scheduler-details";

const queryClient = new QueryClient();
const ROOTDOMAIN = window.location.host.split(".").slice(-2).join(".");

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [accessRoutes, setAccessRoutes] = useState(routes);
  const { pathname } = useLocation();
  const logoImg = `https://images.${ROOTDOMAIN}/public/images/logo.svg`;

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);
  useEffect(() => {
    const domainName =
      ROOTDOMAIN.split(".")[0].charAt(0).toUpperCase() + ROOTDOMAIN.split(".")[0].slice(1);
    document.title = `User Management | ${domainName}`;
  }, [ROOTDOMAIN]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    return allRoutes?.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  };

  const newRoutes = routes?.filter((route) => {
    const token = getToken();
    if (
      !token &&
      window.location.pathname !== "/sign-in" &&
      window.location.pathname !== "/change-password" &&
      window.location.pathname !== "/forgot-password"
    ) {
      window.location.replace(`${window.location.origin}/sign-in`);
    }
    if (!route.permissionsRequired) {
      return true;
    }
    return hasPermissions(route.permissionsRequired);
  });
  useEffect(() => {
    setAccessRoutes(newRoutes);
  }, []);

  const customRoutes = () => {
    const token = getToken();
    return (
      <>
        <Route path="/transactions/:transactionId" element={<TransactionDetail />} />
        <Route path="/reports/:id" element={<SchedulerDetails />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/users" element={<OrganizationUsers />} />
        <Route path="/api-key" element={<ApiKeys />} />
        <Route path="/transactions" element={<MerchantReporting />} />
        <Route path="*" element={<Navigate to={token ? "/users" : "/sign-in"} />} />
      </>
    );
  };

  return direction === "rtl" ? (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          <ToastContainer />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={logoImg}
                brandName="Merchant Portal"
                routes={accessRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>{getRoutes(accessRoutes)}</Routes>
          {customRoutes()}
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  ) : (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={logoImg}
              brandName="Merchant Portal"
              routes={accessRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(accessRoutes)}
          <Route path="/sign-in" element={<SignIn />} />
          {customRoutes()}
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
